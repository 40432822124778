import React, { Suspense, useLayoutEffect, useRef, useState } from 'react';

function ScrollToLoad({ children, offset = 150 }) {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    const checkIfInView = () => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const inView = rect.top <= window.innerHeight + offset;
            if (inView) {
                setIsVisible(true);
                cleanupEventListeners();
            }
        }
    };

    const cleanupEventListeners = () => {
        window.removeEventListener('scroll', checkIfInView);
        window.removeEventListener('resize', checkIfInView);
    };

    useLayoutEffect(() => {
        checkIfInView();
        window.addEventListener('scroll', checkIfInView);
        window.addEventListener('resize', checkIfInView);
        return () => cleanupEventListeners();
    }, []);

    return (
        <>
            <div ref={elementRef} />
            {isVisible && <Suspense fallback={<></>}>{children}</Suspense>}
        </>
    );
}

export default ScrollToLoad;
